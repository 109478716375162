// PW TOOLS JS FRONT
window.showMessage = showMessage
window.popup = popup
window.scrollToGo = scrollToGo
window.copy = copy
window.toggleSidebar = toggleSidebar
window.closeSidebar = closeSidebar
window.closeAllSidebar = closeAllSidebar

// INPUT FILE CUSTOM
var inputFileItems = document.querySelectorAll('.input-file')

for (var i = 0; i < inputFileItems.length; i++) {
    var fileInput = inputFileItems[i].children[0],
        button = inputFileItems[i].children[1],
        the_return = inputFileItems[i].children[2]

    button.addEventListener('keydown', function (event) {
        if (event.keyCode == 13 || event.keyCode == 32) {
            this.previousElementSibling.focus()
        }
    })

    button.addEventListener('click', function (event) {
        this.previousElementSibling.focus()
        return false
    })

    fileInput.addEventListener('change', function (event) {
        var this_return = this.nextElementSibling.nextElementSibling
        this_return.innerHTML = this.value.replace('C:\\fakepath\\', ' ')
    })
} // END

// compat jQuery
$.fn.showModal = function() {
    el = $(this);
    if (el.is('dialog')) {
        el[0].showModal();
    }
    return el;
};

$.fn.close = function() {
    el = $(this);
    if (el.is('dialog')) {
        el[0].close();
    }
    return el;
};

// POPUP
function popup(pop) {
    pop.showModal();
}

function closePopup(popup) {
    popup.close();
}

// Fonction qui affiche une notification en bas du header pendant 3 secondes
// type : ( string ) class qu'aura l'élément ajouté ( valid, error ou delete )
// message : ( string ) message affiché
function showMessage(type, message) {
    var alert = $('<p>')
    alert.addClass('msg-info flash')
    alert.addClass(type)
    alert.html(message)

    $('body').append(alert)

    setTimeout(function () {
        alert.addClass('open')
    }, 100)

    setTimeout(function () {
        alert.removeClass('open')
    }, 3000)

    setTimeout(function () {
        alert.remove()
    }, 3500)
}

// COPY FUNCTION
function copyLegacy(text) {
    let textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus({preventScroll: true})
    textArea.select()
    
    try {
        document.execCommand('copy')
        showMessage('valid','texte copié !');
        
    } catch (err) {
        console.error('Copy error', err)
        showMessage('error','texte non copié !');
    }

    document.body.removeChild(textArea)
}

function copy(selector) {
    let textToCopy = selector.text()

    if (location.protocol === 'https:') {
        // marche uniquement en https
        navigator.clipboard.writeText(textToCopy).then(function() {
            showMessage('valid','texte copié !');
        }, function() {
            showMessage('error','texte non copié !');
        });

    } else {
        copyLegacy(textToCopy)
    }
}

// SCROLL TO GO
function scrollToGo(selector) {
    if (selector.length) {
        if ($('sidebar-toggle').hasClass("is-open")) {
            $('.sidebar-toggle').removeClass("is-open");
        }

        $("html, body").animate({ scrollTop: $(selector).offset().top - ($('header').height()-1) }, 1000);
    }
    return false
}

function toggleSidebar(element) {
    let sidebar = $('#' + element)
    let btn = $('[data-toggle-sidebar="'+element+'"]')

    sidebar.toggleClass('is-open');
    btn.toggleClass('actif');

    if( $('.main-nav').hasClass("showing-nav") ) {
        $('.js-toggle-nav').removeClass('menu-open');
        $('.main-nav').removeClass("showing-nav");
        $('.js-toggle-nav').attr('aria-expanded','false');
    }

    if($('.sidebar-toggle:not(#'+element+')').hasClass("is-open")) {
        $('.sidebar-toggle:not(#'+element+') [data-toggle-sidebar]').attr('aria-expanded','false');
        $('.sidebar-toggle:not(#'+element+') [data-toggle-sidebar]').removeClass('actif');
        $('.sidebar-toggle:not(#'+element+')').removeClass("is-open");
    }

    if(sidebar.hasClass('is-open')) {
        btn.attr('aria-expanded','true')
    } else {
        btn.attr('aria-expanded','false');
    }
}

function closeSidebar(element) {
    let sidebar = $('#' + element)
    let btn = $('[data-toggle-sidebar="'+element+'"]')

    sidebar.removeClass('is-open')
    btn.attr('aria-expanded','false');
    btn.removeClass('actif');
}

function closeAllSidebar() {
    if($('.sidebar-toggle').hasClass("is-open")) {
        $('[data-toggle-sidebar]').attr('aria-expanded','false');
        $('[data-toggle-sidebar]').removeClass('actif');
        $('.sidebar-toggle').removeClass("is-open");
    }
}

$(document).ready(function () {
    // RETOUR EN HAUT - créer balise #gotop
    $('#gotop').on('click', function () {
        //
        $('html, body').animate({ scrollTop: 0 }, 'slow')
        return false
    })

    // BOUTON TOGGLE MAIN NAV
    $('.js-display-nav').on('click', function () {
        $('.main-header').addClass('showing-nav')
        closeAllSidebar()
    })

    $('.js-close-nav').on('click', function() {
        $('.main-header').removeClass('showing-nav')
        return false
    })

    // SCROLL TO
    $('a[href^="#"]').on('click', function() {
        let selector = $(this).attr('href')
        scrollToGo(selector)
    })

    // COPY JS
    $('[data-copy]').on('click', function() {
        let copyTarget = $('#' + $(this).attr('data-copy'))
        copy(copyTarget)
    });

    // POPUP
    $('[data-popup]').on('click', function () {
        let pop = $('#' + $(this).attr('data-popup'))
        popup(pop)
    });

    $('body').on('click', '.close-popup', function () {
        closePopup($(this).parent('.popup'));
    })

    // ONGLETS
    $('[data-onglet]').on('click', function () {
        $('.nav-onglets > button').removeClass('actif')
        $('.onglet-content').removeClass('open')
        $(this).addClass('actif')
        $('#' + $(this).attr('data-onglet')).addClass('open')
    })

    // TOGGLE SECTION
    $('.toggle-item:not(.no-toggle)').on('click', '> .toggle-launcher', function (e) {
        if ($(this).parent().hasClass("open")) {
            $(this).parent().removeClass('open');

        } else {
            $('.toggle-section .toggle-item.open').removeClass('open');
            $(this).parent().addClass('open');
        }
    });

    // RECUP EMAIL NEWSLETTER POPUP
    $('[data-popup-news="inscrinews"]').on('click', function () {
        if ($('#recup-email-news:valid').length != 0) {
            var email = $('#recup-email-news').val()
            $('#email-newsletter').attr('value', email)
            popup( $('#' + $(this).attr('data-popup-news')) )
        }
    })

    $('.link-to-go').click(function (event) {
        event.preventDefault()
        let url = $(this).attr('data-href')
        window.open(url)
    }) 

    // TOGGLE SIDEBAR
    $('[data-toggle-sidebar]').on('click', function () {
        toggleSidebar($(this).attr('data-toggle-sidebar'))
    });

    // CLOSE SIDEBAR
    $('[data-close-sidebar]').on('click', function () {
        closeSidebar($(this).attr('data-close-sidebar'))
    })
}) // FIN DOCUMENT READY
